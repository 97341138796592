import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components';
import { Accordion, Card, Button, Table, Row, Col } from 'react-bootstrap';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { FaRegWindowClose } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import Plus from '../../../assets/plus.svg'
import Cancel from '../../../assets/cancel_line.svg'
import config from '../../../aws-exports';
import CLight from "../../../assets/add_gray.svg";
import { useSelector, useDispatch } from "react-redux";
import {removeCategory,saveCategory,getCategoryByUserID} from '../../../graphql/queries';
import ToastBox from '../../Toast/Toast';
import {FaCircleNotch} from "react-icons/fa";
import  secureLocalStorage  from  "react-secure-storage";
import Sync from "../../../assets/sync.gif";
Amplify.configure(config);

export default function AddCatagory(props) {
    const {consoleLog} = require('../../commonFunctions.js');
    consoleLog('AddCatagory props: ', props);

    const updatedStructure = useSelector((state) => state.templateStructure);
    const [structureIndex, setStructureIndex] = React.useState(props.structureIndex)
    const [addStructureTitle, setAddStructureTitle] = useState(true);
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [default_manufecturer, setDManufecturer] = React.useState(useSelector((state) => state.defaultManufacturer));
    const [default_category, setDCategory] = React.useState(useSelector((state) => state.defaultCategories));
    const [category_by_user_id, setDCategoryByUserId] = React.useState(useSelector((state) => state.getCategoryByUserID));
    const dispatch = useDispatch();

    const [synching, setSynching] = useState(false);
    const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
    };

    const [show, setShow] = useState(false);
    useEffect(() => {
        setStData(updatedStructure);
        consoleLog('AddCatagory stData: ', stData);

        setStructureIndex(props.structureIndex)
        consoleLog("AddCatagory on useEffect sturctureIndex: ", structureIndex)
    }, [structureIndex]);

    async function fetchUserCategory() {
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        showSynching();
        const userCategories = await API.graphql({
            query: getCategoryByUserID,
            variables: {
                user_id: client_id,
                team_id: team_id
            }
        })
        setDCategoryByUserId(userCategories.data.getCategoryByUserID);
    }

    function showHideStructureForm(){        
        consoleLog("showHideStructureForm");
        addStructureTitle ? setAddStructureTitle(false) : setAddStructureTitle(true);
        consoleLog("addStructureTitle: " , addStructureTitle)
    }

    function addDefaultCategory(category){ // add user category to temp / doc
        consoleLog('addDefaultCategory', category);
        category.line_items = [];

        props.onChange(category, structureIndex);
    }

    function deleteCat(defaultCat){ //delete default category
        deleteCategory(defaultCat.category_id);
        var categoryCopy = [...category_by_user_id];

        consoleLog('categopry copy',categoryCopy);
        categoryCopy = categoryCopy.filter(c => c.category_id !== defaultCat.category_id);
        setDCategoryByUserId(categoryCopy);
    }

    async function deleteCategory(category_id) {
        showSynching();
        const deleteTemp = await API.graphql({
            query: removeCategory,
            variables: {
                category_id: category_id
            }
        })
    }

    async function addCategory(category){
        // adding default/existing category to document/template
        consoleLog('addCategory category: ', JSON.stringify(category));
        consoleLog('addCategory structureIndex: ', structureIndex);
        let categoryCopy = JSON.parse(JSON.stringify(category));

        if(categoryCopy.w_line_items){
            delete categoryCopy.e_line_items;
            delete categoryCopy.w_line_items;
        }
        categoryCopy.line_items = [];
        categoryCopy.default_data = null;

        consoleLog('addCategory categoryCopy', categoryCopy);

        props.onAdd(categoryCopy, structureIndex);
    }
    
    const [categoryName, setCategoryName] = useState('');
    const [categoryNameLoader, setCategoryNameLoader] = useState(false);
    
    function saveTemplateCategory(){ // add a default user category
        setCategoryNameLoader(true);
        const category={};
        category.name=categoryName;
        consoleLog('saveTemplateCategory', categoryName);
        category.line_items=[];
        if(!categoryName || categoryName == ''){
            props.setShow(true);
            props.setShowMessage('Please enter Category name.');
            props.setShowMessageType('Error');
            return false;
        }
        insertUserCategory(categoryName);
    }

    async function insertUserCategory(name) {
        // add a default user category
        consoleLog("insertUserCategory-addcategory", name);
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        showSynching();
        const insert_user_category = await API.graphql({
            query: saveCategory,
            variables:{
                user_id: client_id,
                team_id: team_id,
                scope_type_id: 0,
                name:name,
                status:1,
                default_data:''
            }
    
        });
        consoleLog("insert_user_category: ", insert_user_category);
      
        setCategoryName('');
        setCategoryNameLoader(false);
        fetchUserCategory();
    }

    function changeCategoryName(e){
        setCategoryName(e.target.value);
    }

    return (
        <AddStruct>
              {
                !categoryName ?
                    <ToastBox
                    setShow={setShow}
                    show={show}
                    Text={"Please enter Category name."}
                    />
                : null
                }
            <div className="row buttons structure-buttons">
                <a className={`add-structure show-item-adder text-left ${!addStructureTitle ? "borders" : ""}`} onClick={showHideStructureForm}>
                   
                <p className="text m-0" style={{ fontSize: "16px", fontWeight: "500" }}> <span className="mr-3 h-auto"> <img src={CLight} style={{width:'25px', height:'auto'}} alt=""/></span> ADD A CATEGORY</p>
                </a>

                <Col xs={12} className={`section structure-adder item-adder col text-left ${addStructureTitle ? "hide" : ""}`}>
                    <div className="w-100 d-flex align-items-center justify-content-between heading">
                        <h2>Add a Category</h2>
                        <a onClick={showHideStructureForm}> <img src={Cancel} style={{width:'26px', height:'auto', position:'absolute', top:'20px',right:'30px'}} alt=""/> </a>
                    </div>
                    <div className="catBox">
                        <p className="title">Select an existing Category to add</p>
                        <div className="catBox_child bg-white">
                            {default_category.filter(d_cat=>( // default categories
                                props.categories.every(cat=>(cat.category_id != d_cat.category_id))
                            )).map((category, i) => (
                                <button key={i} onClick={()=>addCategory(category)} className="w-100 custombtnn text-dark bg-white text-left ">
                                    {category.name}
                                </button>
                            ))}
                            {category_by_user_id && category_by_user_id.filter(d_cat=>( // default categories
                                props.categories.every(cat=>(cat.category_id != d_cat.category_id))
                            )).map((defaultCat, i) => ( // user categories
                                <div className="d-flex align-items-center">
                                    <button key={i}  className="w-100 text-dark custombtnn  bg-white text-left ">
                                        <span style={{width:"97%", height:"100%"}} onClick={()=>addDefaultCategory(defaultCat)}>{defaultCat.name}</span>
                                        <FaTrashAlt style={{cursor:'pointer'}} onClick={()=>deleteCat(defaultCat)} color={'gray'} size={15}/>
                                    </button>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                    <div className="create-form form">
                        <div>
                    <p className="title">Create a new Category</p>
                        </div>
                        <div>
                        <input type="hidden" name="type" value="markup"/>
                        <div className="input-add-cat">
                            <input onInput={(e)=>changeCategoryName(e)}  type="text" name="name" className="custom-input-x" placeholder="New Category Name" value={categoryName} data-item-adder-focus="true"/>
        
                            <button className="add-btn small" onClick={()=>saveTemplateCategory()} >
                                    {categoryNameLoader
                                        ?
                                        <FaCircleNotch size={22} className="awesome_spinner" style={{color:"#343a40"}}/>
                                        :
                                        <img src={Plus} alt=""/>
                                    }
                            </button>                         
                        </div>
                        </div>
                    </div>
                </Col>
            </div>
            {synching && <img className="sync" src={Sync} />}
        </AddStruct>
    )
}

const AddStruct = styled.div`
    min-height: 41px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 10px;
    
    .custom-input-x{
        height: 40px;
        width: 100%;
        border-radius: 4px;
        padding: 0.71em 0.64em 0.86em;
        border: 1px solid #b4bebf;
        outline: none;
        font-size: 14px;
        line-height: 18px;
        color: #212e33;
        font-style: italic;
    }
    .input-add-cat{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    .structure-buttons {
        margin-top: 0;
    }

    .heading {
        h2 {
            font-size: 18px;
            line-height: 24px;
            font-family:Roboto;
            font-weight: bolder;
            margin-bottom: 33px;
        }
    }

    .add-btn{
        height:40px;
        width:40px;
        background:#D6E1E5;
        margin:0 0 0 15px;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:5px;
        cursor: pointer;
    }
    .custombtnn{
            outline:none;
            font-weight:normal;
            font-family:Roboto;
            display: flex;
            justify-content: space-between;

            &:hover  {
                color: #f7941c !important;
              }
        }

    .catBox_child{
        width: 100%;
        border-radius:5px;
        padding: 5px 0px 0px;
        border: 1px solid #c2c2c2;
        border-bottom: none !important;

        @media(max-width: 767px) {
            padding: .6em .8em;
        }
        button{
            border-bottom:1px solid #c2c2c2;

            @media(max-width: 767px) {
                margin-right: 10px;
              }
        }
        .title{
            font-family: Roboto;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 7px;
        }
       
    }
    .buttons {
        padding: 0;
    }
    
    .row {
        position: relative;
        display: block;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
        float: none;
    }
    .left {
        float: left;
    }
    .add-structure {
        background: #737F82;
        -webkit-transition: opacity .5s;
        transition: opacity .5s;
        padding: .6em 1.8em .6em 1.2em;
        font-size: small;

        @media(max-width: 767px) {
            border-radius : 6px 6px 0 0;
        }
    }
    .show-item-adder {
        display: block;
        cursor: pointer;
        height: 41px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.borders {
            border-radius : 6px 6px 0 0 !important;
        }
    }
    a {
        color: #414141;
        text-decoration: none;
        outline: 0;
    }
    .color-white {
        color: white;
    }
    .add-structure .text {
        font-size:16px;
        font-family:Rajdhani;
        color:#FFFFFF;
    }
    .buttons .show-item-adder {
        display: block;
        cursor: pointer;
        padding: .5em;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        display: block;
        cursor: pointer;
        padding: 8px 14px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        justify-content: flex-start;
    }
    .item-adder.hide {
        min-height: 0;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: none !important;
        display: none;
    }
    .item-adder {
        overflow: hidden;
        -webkit-animation: height-show .5s forwards;
        animation: height-show .5s forwards;
        background: #f5f5f5;
        padding: 2em 3.42em 3.57em 3.42em !important;
        position: relative;
        border: 1 px solid #f5f5f5;
        border-radius: 0 0 10px 10px;

        @media(max-width: 767px) {
            padding: 2em !important;
        }
    }
    .item-adder .close {
        position: absolute;
        top: 1em;
        right: 2em;
        cursor: pointer;
        z-index: 5;catBox_child.1em;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
    }
    .col {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        text-align: center;
        position: relative;
        min-height: 1px;
        line-height: 1em;
        width: 100%;
        float: none;
        margin-right: -.3em !important;
    }
    .col:last-child {
        margin-right: 0 !important;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }
    h2 {
        font: 1.5em "Roboto";
    }
    div, span, form{
        border: 0;
        font-size: 100%;
        font: inherit;
        line-height: 1.4em;
        vertical-align: baseline;
        margin: 0;
        padding: 0;
    }
    .item-adder .create-form {
        margin-top: 2.85em;
        padding-right: 1em;
    }
 
    button, .button, .row .button {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: bold;
        text-transform: uppercase;
        background: #EEF2F4;
        border: 1px solid #08527d;
        border-radius: 3px;
        padding: .8em 1em;
    }
    button, .button, .row .button {
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        background: #EEF2F4;
        border: none;
        border-radius: 3px;
        padding: .6em .8em;
        box-sizing: border-box;
    }
    button.small, .button.small, .row .button.small {
        font-size: .8em;
        padding: .5em 1.2em;
    }
    .item-adder .create-form button {
        padding: .7em 1em;
    }

    .title {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 7px;
    }

    .catBox {
        padding-right: 1em;
    }
`