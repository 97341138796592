import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Menu from "../Navbar/Navbar";
import Structure from "./Structure";
import WOStructure from "./work_order/Structure";
import AddStructure from "./AddStructure";
import AddTaxes from "./AddTaxes";
import AddMarkups from "./AddMarkups";
import AddFinancingOptions from "./AddFinancingOptions";
import TemplateNavbar from "../Navbar/TemplateNavbar";
import DocNavbar from "../Navbar/DocNavbar";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Accordion, Card, Button, Table, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { Link } from "gatsby";
import DragContainer from "./DragContainer";
import Loader from '../../assets/loading.gif';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSleigh } from "@fortawesome/free-solid-svg-icons";
import EdiText from "react-editext";
import {
  FaPlus,
  FaPencilAlt,
  FaWindowClose,
  FaCheck,
  FaPenSquare,
} from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import AddCatagory from "./AddCatagory";
import searchImg from "../../assets/search.svg";
import { FaTrashAlt } from "react-icons/fa";
import {
  getDefaultTemplates,
  getDefaultCategories,
  getCategoryByUserID,
  getMarkups,
  getDocId,
  applyWorkOrderTemplate,
  getDocumentWorkOrderData,
  getDocumentEstimateData,
  saveWorkOrderDoc,
  saveEstimateDoc,
  saveTemplateAs,
  saveTemplateJson,
  updateDocStructureSortOrder,
  elasticSearchUpdateDocOrTemp,
  getFromRedis,
  createOnRedis,
  updateDocNotes,
  updateDocFooter,
  updateDocModDate,
  getDocOrderIds,
  addDocOrders,
  updateStructureCount,
  getFinancingOptions,
  saveFinancingOptions,
  createFinancingOptions
} from "../../graphql/queries";
import { useSelector, useDispatch } from "react-redux";
import Move from "../../assets/move_struc.svg";
import Move2 from "../../assets/move.svg";
import { FaGripVertical } from "react-icons/fa";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import Category from "./Category";
import * as queryString from "query-string";
import makeAnimated from "react-select/animated";
import DocDetails from "../Document/DocDetails";
import Cancel from "../../assets/cross.svg";
import Check from "../../assets/checkmark1.svg";
import TaxIcon from "../../assets/texmark.svg";
import TaxPIcon from "../../assets/add_tax.svg";
import ToastBox from '../Toast/Toast';
import DocSettings from "../Document/DocSettings";
import { GoKebabVertical } from "react-icons/go";
import "../Modals/Modals.css";
import { navigate } from "gatsby";
import  secureLocalStorage  from  "react-secure-storage";
import Sync from "../../assets/sync.gif";
const animatedComponents = makeAnimated();

export default function CreateTemplate(props) {
  const {consoleLog} = require('../commonFunctions.js');
  consoleLog("CreateTemplate props: ", props);
  consoleLog("CreateTemplate props.url_params: ", props.url_params);

  const updatedStructure = useSelector((state) => state.templateStructure);
  const [templateInfo, setDocTypDocTableRowe] = React.useState(
    useSelector((state) => state.templateInfo)
  );
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [template_structures, setTemplateStructures] = React.useState(
    stData.structures
  );
  const [category_by_user_id, setDCategoryByUserId] = React.useState(
    useSelector((state) => state.getCategoryByUserID)
  );
  const [default_category, setDCategory] = React.useState(
    useSelector((state) => state.defaultCategories)
  );
  const template_id = props.url_params.template_id;
  consoleLog("-------------------------------------------", props.url_params.template_id)
  const [addTaxTitle, setAddTaxTitle] = useState(true);
  const [show_taxes_and_markup, set_show_taxes_and_markup] = React.useState(false);
const [showtax, setShowtax] = useState(true);
const [showmarkup, setShowmarkup] = useState(true)
const [showFinancing, setShowFinancing] = useState(true)

  // const [show_taxes_and_markup_view, set_show_taxes_and_markup_view] = React.useState(false);

  const [reportTypes, setReportTypes] = React.useState([]);
  const [starterTemplates, setStarterTemplates] = React.useState("");

  const [project_notes, set_project_notes] = React.useState(stData.notes);
  const [footer_notes, set_footer_notes] = React.useState(stData.footer) || '';

  const dispatch = useDispatch();
  consoleLog("CreateTemplate, templateInfo: ", templateInfo);

  const [synching, setSynching] = useState(false);
  const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
  };

  const [displayStructurePrice, setDisplayStructurePrice] = React.useState(0);
  const [displayCategories, setDisplayCategories] = React.useState(0);
  const [displayCategoryPrice, setDisplayCategoryPrice] = React.useState(0);

  const [showLineItem, toggleShowLineItem] = React.useState(true);
  const [showDocDetail, toggleShowDocDetail] = React.useState(false);
  const [showSettingOption, toggleShowSettingOption] = React.useState(false);

  const [showNote, toggleShowNote] = React.useState(false);
  const [showAdvanceOpt, toggleShowAdvanceOpt] = React.useState(false);
  const [showAddCat, toggleShowAddCat] = React.useState(false);
  const [show, setShow] = useState(false);
  const[toastShow, setToastShow]= useState(false);
  const [applyOnPg, setApplyOnPg] = React.useState(false);
  const handleClickApplyOnPg = () => {
    setApplyOnPg(!applyOnPg);
  };
  const [hideUnPg, setHideUnPg] = React.useState(false);
  const handleClickHideUnPg = () => {
    setHideUnPg(!hideUnPg);
  };

  const [applyOnPi, setApplyOnPi] = React.useState(false);
  const handleClickApplyOnPi = () => {
    setApplyOnPi(!applyOnPi);
  };
  const [hideUnPi, setHideUnPi] = React.useState(false);
  const handleClickHideUnPi = () => {
    setHideUnPi(!hideUnPi);
  };

  const [inputList, setInputList] = useState([]);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    // consoleLog(index, list[index]);
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { notes: "" }]);
  };

  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [subTotal, setSubTotal] = React.useState(0);
  const [matCostTotal, setMatCostTotal] = React.useState(0);
  const [oNpCostTotal, setONpCostTotal] = React.useState(0);

  const [total_markupx, set_total_markupx] = React.useState(0);
  const [total_op_markupx, set_total_op_markupx] = React.useState(0);

  const [total_material_costx, set_total_material_costx] = React.useState(0);
  const [total_costx, set_total_costx] = React.useState(0);

  const [default_tNm, set_default_tNm] = React.useState([]);

  const [taxes, setTaxes] = React.useState([]);
  const [markups, setMarkups] = React.useState([]);

  const [financingOptions, setFinancingOptions] = React.useState([]);
  const [reportLoading, setReportLoading] = React.useState(false);
  async function fetchFinancingOptions() {
    if (typeof stData.financing_options != 'undefined' && stData.financing_options != '' && stData.financing_options != null) {
      consoleLog("fetchFinancingOptions found>", JSON.parse(stData.financing_options));
      setFinancingOptions(JSON.parse(stData.financing_options));
      return false;
    }else{
      if(typeof props.url_params.doc_id != 'undefined' && props.url_params.doc_type_id == 1){
        consoleLog("fetchFinancingOptions doc_id>", props.url_params.doc_id);
        showSynching();
        const get_financing_options = await API.graphql({
          query: getFinancingOptions,
          variables: {
            doc_id: props.url_params.doc_id
          },
        });
        consoleLog("fetchFinancingOptions get_financing_options>", get_financing_options);
        let data = JSON.parse(get_financing_options?.data?.getFinancingOptions);
        consoleLog("fetchFinancingOptions data>", data);
        if(data.length > 0){
          setFinancingOptions(data);
          let stDataCopy = stData;
          stDataCopy.financing_options = JSON.stringify(data);
          setStData(stDataCopy);

          return false;
        }else{
          let userId = stData.user_id;
          let docId = props.url_params.doc_id;
          consoleLog("fetchFinancingOptions userId>", userId);
          let f_options = [{"user_id": userId, "doc_id":docId, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"},{"user_id": userId, "doc_id":docId, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"},{"user_id": userId, "doc_id":docId, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"}];
         
          consoleLog("fetchFinancingOptions f_options>", f_options);

          setFinancingOptions(f_options);

          let stDataCopy = stData;
          stDataCopy.financing_options = JSON.stringify(f_options);
          setStData(stDataCopy);

          return false;
        }
      }else{
        if(stData.doc_type_id == 1){
          console.log('fetchFinancingOptions template stData>', stData);
          let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
          let userId = stData.user_id ? stData.user_id : client_id;

          consoleLog("fetchFinancingOptions userId>", userId);
          let f_options = [{"user_id": userId, "doc_id":0, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"},{"user_id": userId, "doc_id":0, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"},{"user_id": userId, "doc_id":0, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"}];
          consoleLog("fetchFinancingOptions f_options>", f_options);

          setFinancingOptions(f_options);

          let stDataCopy = stData;
          stDataCopy.financing_options = JSON.stringify(f_options);
          setStData(stDataCopy);

          return false;
        }
      }
    }
  }

  const getDateTime = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let date = [year, month, day].join('-');

    let hour = d.getHours();
    let minutes = d.getMinutes();

    if (hour.length < 2)
        hour = '0' + hour;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    let time = [hour, minutes].join(':');

    return date+' '+time+':00'; 
  }

  async function updateFinancingOptionsFunc(updatedFinance,options,index){
    if(typeof props.url_params.doc_id != 'undefined'){
      consoleLog("updateFinancingOptionsFunc updatedFinance>",updatedFinance);
      consoleLog("updateFinancingOptionsFunc options>",options);
      showSynching();
      const update_financing_options = await API.graphql({
        query: saveFinancingOptions,
        variables: {
        options:JSON.stringify(updatedFinance)
        },
      });
      let result=JSON.parse(update_financing_options?.data?.saveFinancingOptions)
      consoleLog('updateFinancingOptionsFunc result>',result);
      if(result.body.operation=="update"){
        let stDataCopy = stData;
        stDataCopy.financing_options = JSON.stringify(options);
        setStData(stDataCopy);
      }else{
        let changedOptions=options;
        let changedFinance=updatedFinance;
        changedFinance.id=result.body.id;
        changedOptions[index]=changedFinance;
        console.log("changedFinance",changedFinance)
        console.log("changed Options",changedOptions);
        setFinancingOptions(changedOptions)
        let stDataCopy = stData;
        stDataCopy.financing_options = JSON.stringify(changedOptions);
        setStData(stDataCopy);
      }
      updateDocOnElasticSearch();
    }else{
      consoleLog("updateFinancingOptionsFunc updatedFinance>",updatedFinance, index);
      consoleLog("updateFinancingOptionsFunc options>",options);
      let stDataCopy = stData;
      stDataCopy.financing_options = JSON.stringify(options);
      setStData(stDataCopy);
    }
  }

  async function updateDocOnElasticSearch(){
    consoleLog('updateDocOnElasticSearch updatedStructure', updatedStructure);
    consoleLog('updateDocOnElasticSearch', stData);

    let stDataCopy = stData;

    consoleLog('updateDocOnElasticSearch financingOptions', financingOptions);
    stDataCopy.pdf = '';
    stDataCopy.invoice_pdf = '';
    let m_date = getDateTime();
    stDataCopy.date_updated = new Date(m_date);
    
    let temp = JSON.stringify(stDataCopy);
    consoleLog('updateDocOnElasticSearch temp', temp);
    showSynching();
    const update_elastic = await API.graphql({
      query: elasticSearchUpdateDocOrTemp,
      variables: {
        id: props.url_params.doc_id,
        index:'prodocs-documents',
        template:temp
      },
    });
    consoleLog('updateDocOnElasticSearch', update_elastic);
    let update_elasticx = JSON.parse(JSON.parse(update_elastic.data.elasticSearchUpdateDocOrTemp));
    let update_status = update_elasticx.status;
    consoleLog('updateDocOnElasticSearch update_elasticx', update_elasticx);
    consoleLog('updateDocOnElasticSearch update_status', update_status);

    let update_flag;
    let doc_found = false;
    let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
    for (let index = 0; index < es_down_docs.length; index++) {
      if(es_down_docs[index].doc_id == props.url_params.doc_id){
          doc_found = true;
          break;
      }
    }
    consoleLog('updateDocOnElasticSearch es_down_docs doc_found', doc_found);

    if(update_status == 200){
      update_flag = '0';
      if(doc_found){
        let temp_docs = es_down_docs.filter(d =>d.doc_id != props.url_params.doc_id);
        consoleLog('updateDocOnElasticSearch es_down_docs temp_docs>>>', temp_docs);

        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
        consoleLog('updateDocOnElasticSearch es_down_docs temp_docs_temp>>>', temp_docs_temp);
        temp_docs_temp.body.docs = temp_docs;
        consoleLog('updateDocOnElasticSearch es_down_docs temp_docs_temp final>>>', temp_docs_temp);
        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
      }
    }else{
      update_flag = '1';
      if(!doc_found){
        es_down_docs.push({doc_id : props.url_params.doc_id, es_down_flag : '1'});
        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
        consoleLog('updateDocOnElasticSearch es_down_docs temp_docs_temp>>>', temp_docs_temp);
        temp_docs_temp.body.docs = es_down_docs;
        consoleLog('updateDocOnElasticSearch es_down_docs temp_docs_temp final>>>', temp_docs_temp);
        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
      }
    }
    consoleLog('updateDocOnElasticSearch es_down_docs update_flag', update_flag);

    let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let date_time = getDateTime();

    const update_doc_info = API.graphql({
      query: updateDocModDate,
      variables: {
        doc_id: props.url_params.doc_id,
        date_updated:date_time,
        updated_by:userId,
        pdf:'',
        es_down_flag:update_flag
      },
    });
    consoleLog('updateDocOnElasticSearch', update_doc_info);
  }

  async function fetchDefaultTaxesMarkups() {
    let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let teamId = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
    consoleLog("client id>", userId);
    consoleLog("team id>", teamId);
    showSynching();
    const taxAndMarkups = await API.graphql({
      query: getFromRedis,
      variables: {
        id: userId,
        index: 'prodocs_taxes_and_markups',
      },
    });
    let taxAndMarkupsData = JSON.parse(taxAndMarkups.data.getFromRedis);
    consoleLog('fetchDefaultTaxesMarkups rd:', taxAndMarkupsData);
    if(taxAndMarkupsData.body != null) {
      set_default_tNm(JSON.parse(taxAndMarkupsData.body));
    } else {
      showSynching();
      const def_tm = await API.graphql({
        query: getMarkups,
        variables: {
          user_id: userId,
          team_id: teamId,
        },
      });
      let defTM = JSON.parse(def_tm.data.getMarkups);
      set_default_tNm(defTM);
      consoleLog('fetchDefaultTaxesMarkups db:', defTM)
      showSynching();
      const create_tm_redis = await API.graphql({
        query: createOnRedis,
        variables: {
          id: userId,
          index: 'prodocs_taxes_and_markups',
          data: def_tm.data.getMarkups
        }
      });
      consoleLog('fetchDefaultTaxesMarkups create_tm_redis:', create_tm_redis);
    } 
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function calculateAdjQuantity(line_item) {
    let adjusted_quantityx = +line_item.quantity+(line_item.quantity*line_item.waste/100);
    adjusted_quantityx = formatter.format(adjusted_quantityx);
    adjusted_quantityx = Number.parseFloat(adjusted_quantityx);
    console.log('calculateAdjQuantity', adjusted_quantityx);
    return adjusted_quantityx;
  }

  function calculateSubTotal(allData) {
    consoleLog("calculateSubTotal", allData);
    let i;
    let j;
    let k;
    let subtotal = 0;
    let matCost = 0;
    let oNpCost = 0;
    let totalQuantity = 0;
    for (i = 0; i < allData.structures.length; i++) {
      for (j = 0; j < allData.structures[i]?.categories.length; j++) {

        if(allData.structures[i].categories[j]?.line_items){

          for (k = 0; k < allData.structures[i].categories[j].line_items.length; k++) {
            let adj_qty = calculateAdjQuantity(allData.structures[i].categories[j].line_items[k]);
            let qty = 0;
            let net_cost = 0;
            if(adj_qty == 0){
              net_cost = (allData.structures[i].categories[j].line_items[k].cost*1) * (allData.structures[i].categories[j].line_items[k].quantity*1);
              qty = allData.structures[i].categories[j].line_items[k].quantity*1;
            }else{
              net_cost = (allData.structures[i].categories[j].line_items[k].cost*1) * (adj_qty*1);
              qty = adj_qty*1;
            }
  
            if (allData.structures[i].categories[j].line_items[k].unit != "") {
              subtotal = subtotal + 1 * net_cost;
              totalQuantity = totalQuantity + qty;
              matCost = matCost + (1 * allData.structures[i].categories[j].line_items[k].material_cost);
              console.log("calculateSubTotal material_cost:"+k, (1 * allData.structures[i].categories[j].line_items[k].material_cost));
            }
            
            if (allData.structures[i].categories[j].line_items[k].apply_op == "1" && allData.structures[i].categories[j].line_items[k].unit != "") {
              oNpCost = oNpCost + 1 * net_cost;
            }
          }

        }
        
      }
    }
    subtotal = Number.parseFloat(subtotal).toFixed(2);
    matCost = Number.parseFloat(matCost);
    oNpCost = Number.parseFloat(oNpCost);

    setSubTotal(subtotal);
    setMatCostTotal(matCost);
    setONpCostTotal(oNpCost);
    setTotalQuantity(totalQuantity);

    consoleLog("calculateSubTotal x subtotal> ", subtotal);
    consoleLog("calculateSubTotal x matCost> ", matCost);
    consoleLog("calculateSubTotal x oNpCost> ", oNpCost);

    // consoleLog("calculateSubTotal x subtotal> ", subTotal);
    // consoleLog("calculateSubTotal x matCost> ", matCostTotal);
    // consoleLog("calculateSubTotal x oNpCost> ", oNpCostTotal);

    getDatafromAddMarkups(allData, subtotal);
    getDatafromAddTaxes(allData, subtotal);
  }

  function getDatafromAddMarkups(data, subtotal) {
    //to calculate markups
    consoleLog("getDatafromAddMarkups", data);
    if(typeof data.markups == 'undefined' || data.markups == '' || data.markups == null){
      setMarkups([]);
      set_total_markupx(0);
      set_total_op_markupx(0);
      return false;
    }
    var allTaxes = Object.entries(data.markups);
    consoleLog("getDatafromAddMarkups", allTaxes);
    // set_total_markupx(val1);
    // set_total_op_markupx(val2);

    var totalMarkup = 0;
    var totalOPMarkup = 0;

    if (allTaxes.length > 0) {
      // consoleLog('AddMarkups props: ', allTaxes);
      // consoleLog('AddMarkups props: ', allTaxes.length);
      // consoleLog('AddMarkups array: ', allTaxes[0]);
      // consoleLog('AddMarkups array: ', allTaxes[1]);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].type);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].markup_id);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].name);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].value);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].sort_order);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].percent);
      // consoleLog('AddMarkups array: ', allTaxes[1][1].for);

      let i;
      var taxesArray = [];
      var taxesElems = {};
      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "markup" || allTaxes[i][1].type == "op") {
          // we this bug in the legacy prodocs type must not be 'op'
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddMarkups i: ', taxesElems);
          
          //calculations for total markup
          if (allTaxes[i][1].type == "markup") {
            totalMarkup = totalMarkup + 1 * allTaxes[i][1].value;
            let total = subtotal * (allTaxes[i][1].value / 100);
            // taxesElems.total = total.toFixed(2);
            taxesElems.total = formatter.format(total);
          }
          //calculations for total OP markup
          if (allTaxes[i][1].type == "op") {
            totalOPMarkup = totalOPMarkup + 1 * allTaxes[i][1].value;
            let totalOp = oNpCostTotal * (allTaxes[i][1].value / 100);
            // taxesElems.total = totalOp.toFixed(2);
            taxesElems.total = formatter.format(totalOp);
          }
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      // consoleLog('AddMarkups data: ', allTaxes);
      consoleLog('AddMarkups array: ', taxesArray);

      //calculations

      // consoleLog('AddMarkups subTotal: ', subtotal);
      // consoleLog('AddMarkups totalMarkup: ', totalMarkup);
      var subTotalPercentage = subtotal * (totalMarkup / 100);
      // subTotalPercentage = Math.round(subTotalPercentage * 100) / 100;
      // subTotalPercentage = Number.parseFloat(subTotalPercentage).toFixed(2);
      subTotalPercentage = formatter.format(subTotalPercentage);
      console.log("AddMarkups subTotalPercentage: ", subTotalPercentage);

      // consoleLog('AddMarkups oNpCostTotal: ', oNpCostTotal);
      // consoleLog('AddMarkups totalOPMarkup: ', totalOPMarkup);
      var oNpCostTotalPercentage = oNpCostTotal * (totalOPMarkup / 100);
      // oNpCostTotalPercentage = Math.round(oNpCostTotalPercentage * 100) / 100;
      // oNpCostTotalPercentage = Number.parseFloat(oNpCostTotalPercentage).toFixed(2);
      oNpCostTotalPercentage = formatter.format(oNpCostTotalPercentage);
      console.log(
        "AddMarkups oNpCostTotalPercentage: ",
        oNpCostTotalPercentage
      );
      
      setMarkups(taxesArray);
      set_total_markupx(subTotalPercentage);
      set_total_op_markupx(oNpCostTotalPercentage);
    }else{
      setMarkups([]);
      set_total_markupx(0);
      set_total_op_markupx(0);
    }
  }

  function getDatafromAddTaxes(data, subtotal) {
    //to calculate taxes
    consoleLog("getDatafromAddTaxes", data);
    if(typeof data.markups == 'undefined' || data.markups == '' || data.markups == null){
      setTaxes([]);
      set_total_material_costx(0);
      set_total_costx(0);
      return false;
    }
    var allTaxes = Object.entries(data.markups);
    consoleLog("getDatafromAddTaxes", allTaxes);
    // set_total_material_costx(val1);
    // set_total_costx(val2);

    var totalMatCost = 0;
    var totalCostPercentage = 0;

    if (allTaxes.length > 0) {
      
      let i;
      var taxesArray = [];
      var taxesElems = {};
      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "tax") {
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddTaxes i: ', taxesElems);
          
          //calculations for material cost
          if (allTaxes[i][1].for == "material") {
            totalMatCost = totalMatCost + 1 * allTaxes[i][1].value; // ading the percentages to calculate material cost
            let matCost = matCostTotal * (allTaxes[i][1].value / 100);
            // taxesElems.total = matCost.toFixed(2);
            taxesElems.total = formatter.format(matCost);
          }
          //calculations for total cost
          if (allTaxes[i][1].for == "all") {
            var percentage_one =
              subtotal * ((1 * allTaxes[i][1].percent) / 100);
            var percentage_two =
              percentage_one * ((1 * allTaxes[i][1].value) / 100);
            // let percentage = percentage_two.toFixed(2);
            let percentage = formatter.format(percentage_two);
            totalCostPercentage += parseFloat(percentage);
            taxesElems.total = percentage;
            // totalCostPercentage = Math.round(totalCostPercentage * 100) / 100;
          }
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      // consoleLog('AddTaxes array: ', taxesArray);

      //calculations

      // consoleLog('AddTaxes subTotal: ', subtotal);

      consoleLog("AddTaxes matCostTotal: ", matCostTotal);
      consoleLog("AddTaxes totalMatCost: ", totalMatCost);
      var totalMatCostPercentage = matCostTotal * (totalMatCost / 100);
      // totalMatCostPercentage = Math.round(totalMatCostPercentage * 100) / 100;
      // totalMatCostPercentage = Number.parseFloat(totalMatCostPercentage).toFixed(2);
      totalMatCostPercentage = formatter.format(totalMatCostPercentage);
      console.log("AddTaxes totalMatCostPercentage: ", totalMatCostPercentage);

      consoleLog("AddTaxes totalCostPercentage:", totalCostPercentage);
      setTaxes(taxesArray);
      set_total_material_costx(totalMatCostPercentage);
      // set_total_costx(Number.parseFloat(totalCostPercentage).toFixed(2));
      set_total_costx(formatter.format(totalCostPercentage));
    }
    else{
      setTaxes([]);
      set_total_material_costx(0);
      set_total_costx(0);
    }
  }

  function showHideTaxesForm() {
    addTaxTitle ? setAddTaxTitle(false) : setAddTaxTitle(true);
    // consoleLog("addStructureTitle: " , addStructureTitle)
  }

  function hideMarkupOrTaxes(type){
    if(type === 'taxes'){
      setShowtax(false);
      if(showmarkup === false && showFinancing === false){
        set_show_taxes_and_markup(false);
      }
    }
    else if(type === 'markups'){
      setShowmarkup(false);
      if(showtax === false && showFinancing === false){
        set_show_taxes_and_markup(false);
      }
    }
    else if(type === 'financing'){
      setShowFinancing(false);
      if(showmarkup === false && showtax === false){
        set_show_taxes_and_markup(false);
      }
    }
  }

  function handleStructures(new_struct) {
    consoleLog("handleStructures", new_struct);
    const update_struct = { ...new_struct };
    setStData(update_struct);
    dispatch({ type: "TEMPLATE_STRUCTURE", value: update_struct });
  }
  function addNewStruct(new_struct) {
    consoleLog("addNewStruct", new_struct);
    const deleteStruct = { ...new_struct };
    dispatch({ type: "TEMPLATE_STRUCTURE", value: deleteStruct });
    setStData(deleteStruct);
  }
  function handleOnDragEndStructure(results) {
    consoleLog("handleOnDragEndStructure structure dragged>");
    if (!results.destination) return;

    consoleLog("handleOnDragEndStructure orignal>", stData);

    const itemsStructure = Array.from(stData.structures);

    consoleLog("handleOnDragEndStructure itemsStructure>", itemsStructure);

    const [reorderedItemStructure] = itemsStructure.splice(
      results.source.index,
      1
    );
    itemsStructure.splice(results.destination.index, 0, reorderedItemStructure);

    if(typeof props.url_params.doc_id != 'undefined'){
      itemsStructure.forEach((str, i)=>{
        API.graphql({
          query: updateDocStructureSortOrder,
          variables: {
            doc_structure_id: str.doc_structure_id,
            sort_order: i
          }
        });
      });
    }
    
    let stCopy = { ...stData };
    stCopy.structures = [...itemsStructure];
    dispatch({ type: "TEMPLATE_STRUCTURE", value: stCopy });
    setStData(stCopy);

    stCopy.pdf = '';
    stCopy.invoice_pdf = '';
    let m_date = getDateTime();
    stCopy.date_updated = new Date(m_date);

    consoleLog("handleOnDragEndStructure handleOnDragEndStructure stCopy>", stCopy);
    if(typeof props.url_params.doc_id != 'undefined'){
      let temp = JSON.stringify(stCopy);
      const update_elasticx = API.graphql({
        query: elasticSearchUpdateDocOrTemp,
        variables: {
          id: props.url_params.doc_id,
          index:'prodocs-documents',
          template:temp
        },
      });
      consoleLog('handleOnDragEndStructure handleOnDragEndStructure', update_elasticx);
    }
  }

  function onChangeCategoryPosition(cate, structure_index) {
    consoleLog("onChangeCategoryPosition cate: ", cate);
    consoleLog("onChangeCategoryPosition structure_index: ", structure_index);

    let strData = [...template_structures];
    consoleLog("onChangeCategoryPosition strData before: ", strData);

    strData[structure_index].categories = cate;
    consoleLog("onChangeCategoryPosition strData after: ", strData);

    let stCopy = { ...stData };
    consoleLog("onChangeCategoryPosition stCopy before: ", stCopy);

    stCopy.structures = strData;
    consoleLog("onChangeCategoryPosition stCopy after", stCopy);
    dispatch({ type: "TEMPLATE_STRUCTURE", value: stCopy });
    setTemplateStructures(strData);
  }

  function showLineItems() {
    consoleLog("showLineItems");
    toggleShowLineItem(true);
    toggleShowDocDetail(false);
    toggleShowSettingOption(false);
  }

  function showDocDetails() {
    consoleLog("showDocDetails");
    toggleShowDocDetail(true);
    toggleShowSettingOption(false);
    toggleShowLineItem(false);
  }

  function showSettingOptions() {
    consoleLog("showSettingOptions");
    toggleShowSettingOption(true);
    toggleShowLineItem(false);
    toggleShowDocDetail(false);
  }

  async function updateProjectNotes(){
    consoleLog('updateProjectNotes', project_notes);
    const project_notes = JSON.stringify(project_notes);
    if(props.url_params.doc_id != 'undefined'){
      showSynching();
      const update_project_notes = await API.graphql({
        query: updateDocNotes,
        variables: {
          doc_id: props.url_params.doc_id,
          notes: project_notes,
        },
      });
      consoleLog('updateProjectNotes', update_project_notes);
      consoleLog('updateProjectNotes', stData);
      updateDocOnElasticSearch();
    }
  }

  const handleProjectNotes = (e) => {
    // consoleLog("handleProjectNotes > ", e.target.value);
    set_project_notes(e.target.value);

    let allData = stData;
    // consoleLog('handleProjectNotes >>>',allData.notes);

    allData.notes = e.target.value;
    setStData(allData);
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    // consoleLog('handleProjectNotes >>>>>',allData.structures[0].categories[0].line_items[0].net_cost);
  };

  const handleFooterNotes = (e) => {
    consoleLog("handleFooterNotes > ", e.target.value);
    if(e.target.value.length <= 110) {
      set_footer_notes(e.target.value);
    }
    else {
      set_footer_notes(e.target.value.substring(0, 110));
      setToastShow(true);
    }
    
  };

  async function updateFooterNotes(){
    consoleLog('updateFooterNotes', footer_notes);
    consoleLog('updateFooterNotes', footer_notes.length);

    if(footer_notes.length <= 110){
      let allData = stData;
      consoleLog("updateFooterNotes >>>", allData.footer);
      allData.footer = footer_notes;
      setStData(allData);
      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    }else{
      consoleLog('updateFooterNotes xxx');
      setToastShow(true);
      return false;
    }

    if(props.url_params.doc_id != 'undefined'){
      showSynching();
      const update_footer_notes = await API.graphql({
        query: updateDocFooter,
        variables: {
          doc_id: props.url_params.doc_id,
          footer: footer_notes,
        },
      });
      consoleLog('updateFooterNotes', update_footer_notes);
      consoleLog('updateFooterNotes', stData);
      updateDocOnElasticSearch();
    }
  }

  const [loading, setLoading] = React.useState(false);
  const [modalShow5, setModalShow5] = React.useState(false);
  const [tempId, setTempId] = React.useState(0);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [reportErrorMsg, setReportErrorMsg] = React.useState('');

  function checkReportError() {
    consoleLog('checkReportError>', secureLocalStorage.getItem("report_error"));
    if(typeof secureLocalStorage.getItem("report_error") != 'undefined' && secureLocalStorage.getItem("report_error") != '' && secureLocalStorage.getItem("report_error") != null){
      let copy = secureLocalStorage.getItem("report_error");
      setReportErrorMsg(copy);
      secureLocalStorage.setItem('report_error', '');
    }
  }

  async function saveDocEstimate(){ //save the document estimate
    /*setLoading(true);

    let data = JSON.stringify(stData);
    consoleLog('saveDocEstimate>',data);
    consoleLog('saveDocEstimate>',props.url_params.doc_id);
    const docData = API.graphql({
      query: saveEstimateDoc,
      variables: {
        doc_id: props.url_params.doc_id,
        data: data
      },
    });
    consoleLog('saveDocEstimate>',docData);

    setLoading(false);
    // setModalShow4(true);*/
    if(!synching){
      if(totalQuantity > 0) {
        navigate('/pdftable?preview=estpdf&doc_id=' + props.url_params.doc_id);
      }else {
        setErrorMsg(true);
      }
    }
  }

  function invoice() {
    if(!synching){
      if(totalQuantity > 0) {
        navigate('/pdftable?invoice=1&preview=estpdf&doc_id=' + props.url_params.doc_id);
      }else {
        setErrorMsg(true);
      }
    }
  }

  function materialCost(){
    if(!synching){
      if(totalQuantity > 0) {
        navigate('/materialCostPdf?invoice=1&preview=wrkpdf&doc_id=' + props.url_params.doc_id);
      }else {
        setErrorMsg(true);
      }
    }
  }


  async function saveDocWorkOrder(){ //save the document work order
    if(!synching){
      if(totalQuantity > 0) {
        navigate('/materialCostPdf?preview=wrkpdf&doc_id=' + props.url_params.doc_id);
      }else {
        setErrorMsg(true);
      }
    }
  }

  const [satName, setSatName] = React.useState('');
  const [exitingTemplateID, setExitingTemplateID] = React.useState(props.url_params.template_id);
  const [satDesc, setSatDesc] = React.useState('');
  const [satError, setSatError] = React.useState(false);
  const [satErrorx, setSatErrorx] = React.useState(false);

  async function saveAsTemplate(){ // create a template of document
    if(!satName && !exitingTemplateID){
      setSatError(true);
      consoleLog('saveAsTemplate if');
      return false;
    }else{
      setSatError(false);
      consoleLog('saveAsTemplate else');
    }
    setModalShow6x(false);
    setLoading(true);
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    consoleLog('saveAsTemplate client_id>',client_id);
    let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
    consoleLog('saveAsTemplate team_id>',team_id);
    let client_email = JSON.parse(secureLocalStorage.getItem('client_data')).email;
    consoleLog('saveAsTemplate client_email>',client_email);
    let doc_type = props.url_params.doc_type_id;
    consoleLog('saveAsTemplate doc_type>',doc_type);
    consoleLog('saveAsTemplate satName>',satName);
    consoleLog('saveAsTemplate satDesc>',satDesc);
    
    let all_data = {...stData};
    all_data.user_email = client_email;
    all_data.description = satDesc;
    all_data.name = satName;
    all_data.user_id = client_id;
    all_data.shared = 0;
    delete all_data.quantities;
    //removing unwanted ids
    delete all_data.doc_id;
    for (let str=0; str < all_data.structures.length; str++) {
      // consoleLog('str>>>>>> str',all_data.structures[str].doc_structure_id);
      // consoleLog('str>>>>>> doc',all_data.structures[str].doc_id);
      delete all_data.structures[str].doc_structure_id;
      delete all_data.structures[str].doc_id;
      for (let cat=0; cat<all_data.structures[str].categories.length; cat++) {
        // consoleLog('cat>>>>>> str',all_data.structures[str].categories[cat].doc_structure_id);
        // consoleLog('cat>>>>>> cat',all_data.structures[str].categories[cat].doc_category_id);
        // consoleLog('cat>>>>>> doc',all_data.structures[str].categories[cat].doc_id);
        delete all_data.structures[str].categories[cat].doc_structure_id;
        delete all_data.structures[str].categories[cat].doc_category_id;
        delete all_data.structures[str].categories[cat].doc_id;
        for(let line=0; line<all_data.structures[str].categories[cat].line_items.length;line++){
          delete all_data.structures[str].categories[cat].line_items[line].doc_structure_id;
          delete all_data.structures[str].categories[cat].line_items[line].doc_category_id;
          delete all_data.structures[str].categories[cat].line_items[line].doc_line_item_id;
          delete all_data.structures[str].categories[cat].line_items[line].doc_id;
          // consoleLog('line>>>>>> str',all_data.structures[str].categories[cat].line_items[line].doc_structure_id);
          // consoleLog('line>>>>>> cat',all_data.structures[str].categories[cat].line_items[line].doc_category_id);
          // consoleLog('line>>>>>> line',all_data.structures[str].categories[cat].line_items[line].doc_line_item_id);
          // consoleLog('line>>>>>> doc',all_data.structures[str].categories[cat].line_items[line].doc_id);
          // consoleLog('line>>>>>> name',all_data.structures[str].categories[cat].line_items[line].name);
        }
      }
    }
    all_data.template_id = exitingTemplateID;
    if (typeof all_data.options == 'string') {
      all_data.options = JSON.parse(all_data.options);
    }
    let data_to_save = JSON.stringify(all_data);
    consoleLog('saveAsTemplate all_data>',all_data);
    showSynching();
     const create_temp = await API.graphql({
      query: saveTemplateAs,
      variables: {
        doc_type_id: doc_type,
        user_id: client_id,
        team_id: team_id,
        data: data_to_save,
        name: satName,
        update_user_id:client_id,
        description:satDesc,
        template_id:exitingTemplateID
      }
    });
    let temp_arr = JSON.parse(create_temp.data.saveTemplateAs);
    consoleLog('saveAsTemplate>>>',temp_arr.status);
    setLoading(false);
    setModalShow3(true);
  }

  const [save, setSave] =  React.useState('');
  const [modalShow2, setModalShow2] = React.useState(false);
  const [templateName , setTemplateName] = useState(templateInfo.name);
  async function saveTemplate(){
    setLoading(true);
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let date_time = getDateTime();
    let all_data = {...updatedStructure};
    all_data.name = templateName;
    let data_to_save = JSON.stringify(all_data);
    consoleLog('saveTemplate x all_data', all_data);
    consoleLog('saveTemplate x templateInfo', templateInfo);

    let m_date = getDateTime();
    all_data.date_updated = new Date(m_date);

    let temp = JSON.stringify(all_data);
    consoleLog('saveTemplate x all_data', all_data);
    showSynching();
    const update_elastic_temp = await API.graphql({
      query: elasticSearchUpdateDocOrTemp,
      variables: {
        id: templateInfo.template_id,
        index:'prodocs-templates',
        template:temp
      },
    });
    consoleLog('saveTemplate x update_elastic_temp:', update_elastic_temp);
    let update_elasticx = JSON.parse(JSON.parse(update_elastic_temp.data.elasticSearchUpdateDocOrTemp));
    let update_status = update_elasticx.status;
    consoleLog('saveTemplate x update_elasticx', update_elasticx);
    consoleLog('saveTemplate x update_status', update_status);

    let es_down_temps = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
    let temp_found = false;
    consoleLog('saveTemplate x es_down_docs>>>', es_down_temps);
    for (let index = 0; index < es_down_temps.length; index++) {
        if(es_down_temps[index].template_id == template_id){
            temp_found = true;
            break;
        }
    }
    consoleLog('saveTemplate x es_down_docs temp_found>>>', temp_found);

    let update_flag = '1';
    if(update_status == 200){
      update_flag = '0';
      let temp_docs = es_down_temps.filter(d =>d.template_id != templateInfo.template_id);
      consoleLog('saveTemplate x es_down_docs temp_docs>>>', temp_docs);

      let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
      consoleLog('saveTemplate x es_down_docs temp_docs_temp>>>', temp_docs_temp);
      temp_docs_temp.body.temps = temp_docs;
      consoleLog('saveTemplate x es_down_docs temp_docs_temp final>>>', temp_docs_temp);
      secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
    }else{
      update_flag = '1';
      if(!temp_found){
        es_down_temps.push({template_id : templateInfo.template_id, es_down_flag : '1'});
        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
        consoleLog('saveTemplate x es_down_docs temp_docs_temp>>>', temp_docs_temp);
        temp_docs_temp.body.temps = es_down_temps;
        consoleLog('saveTemplate x es_down_docs temp_docs_temp final>>>', temp_docs_temp);
        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
      }
    }
    consoleLog('saveTemplate x es_down_docs update_flag', update_flag);
    showSynching();
    const save_template_obj = await API.graphql({
      query: saveTemplateJson,
      variables: {
          template_id: templateInfo.template_id,
          data: data_to_save,
          date_updated:date_time,
          update_user_id:client_id,
          es_down_flag:update_flag
      }
  });
  consoleLog("saveTemplate x save_template_obj: ", save_template_obj);

    setSave(save_template_obj);
    setModalShow2(true);
    setLoading(false);
}

  useEffect(() => {
    if (stData.structures == "" || stData.structures == undefined) {
      consoleLog("template structure undefined");
    }
    setStData(updatedStructure);
    consoleLog("CreateTemplate, stData: ", stData);
    consoleLog("useEffect, stData.structures: ", stData.structures);
    setTemplateStructures(updatedStructure.structures);
    calculateSubTotal(stData);
    if(default_tNm.length === 0) {
      fetchDefaultTaxesMarkups();
    }
    checkReportError();
  }, [updatedStructure, templateInfo, subTotal, matCostTotal, oNpCostTotal]);

  useEffect(() => {
    fetchFinancingOptions();
    let tempID = props.url_params.template_id;
    console.log("Updating...........tempid", tempID , templateInfo.template_id);
    if(stData.template_id != tempID)
    {
      tempID = stData.template_id;
      console.log("Updating...........tempid", tempID);
      setExitingTemplateID(tempID);
    }
  }, [stData]);

  var grandTotal =
    Number.parseFloat(subTotal) +
    Number.parseFloat(total_markupx) +
    Number.parseFloat(total_op_markupx) +
    Number.parseFloat(total_material_costx) +
    Number.parseFloat(total_costx);
    
    // consoleLog("Costsss>", Number.parseFloat(subTotal));
    // consoleLog("Costsss>", Number.parseFloat(total_markupx));
    // consoleLog("Costsss>", Number.parseFloat(total_op_markupx));
    // consoleLog("Costsss>", Number.parseFloat(total_material_costx));
    // consoleLog("Costsss>", Number.parseFloat(total_costx));
    // consoleLog("Costsss>-----------------------------------------");
    
  // grandTotal = Math.round(grandTotal*100)/100;
  grandTotal = Number.parseFloat(grandTotal).toFixed(2);

  consoleLog(
    "xxx total_costx Test Tax (5% applied to 50% of Total)> ",
    total_costx
  );
  consoleLog(
    "xxx total_material_costx State Tax (10% of Material Cost)> ",
    total_material_costx
  );
  consoleLog("xxx total_op_markupx Test Markup> ", total_op_markupx);
  consoleLog("xxx grandTotal grandTotal> ", grandTotal);

  async function ApplyDocumentTemplate(temp_id) {
    props.setLoading(true);
    let doc_type = props.url_params.doc_type_id;
    let doc_id = props.url_params.doc_id;
    consoleLog('ApplyDocumentTemplate temp_id>',temp_id);
    consoleLog('ApplyDocumentTemplate doc_type_id>',doc_type);
    consoleLog('ApplyDocumentTemplate doc_id>',doc_id);
    showSynching();
    const applyTemp = await API.graphql({ // delete existing structures, categories, lineitems, taxes and markups
      query: applyWorkOrderTemplate,
      variables: {
        temp_id: temp_id,
        doc_id: doc_id,
        doc_type_id: doc_type
      },
    });
    let apply_temp = JSON.parse(applyTemp.data.applyWorkOrderTemplate);
    consoleLog("ApplyDocumentTemplate res:", apply_temp);
    if(apply_temp.status == 1){
      consoleLog("ApplyDocumentTemplate status:", apply_temp.status);
      consoleLog("ApplyDocumentTemplate data:",apply_temp.data);
      consoleLog("ApplyDocumentTemplate structures:",apply_temp.data.structures);
      consoleLog("ApplyDocumentTemplate markups:",apply_temp.data.markups);
      let allData = stData;
      consoleLog("ApplyDocumentTemplate allData:",allData);
      allData.structures = apply_temp.data.structures;
      allData.markups = JSON.parse(apply_temp.data.markups);
      allData.options = JSON.stringify(apply_temp.data.options);
      allData.contact_id = apply_temp.data.contact_id;
      allData.description = apply_temp.data.description;
      allData.footer = apply_temp.data.footer;
      allData.notes = apply_temp.data.notes;
      allData.invoice_label = apply_temp.data.invoice_label;
      allData.label = apply_temp.data.label;
      allData.template_id = temp_id;
      showSynching();
      const getDocOrders = await API.graphql({
          query: getDocOrderIds,
          variables: {
              doc_id: doc_id
          }
      });
      
      let ordersData = JSON.parse(getDocOrders.data.getDocOrderIds);
      
      let scopeIds = [];
      for(const order of ordersData) {
          scopeIds.push(order.scope_id);
      }

      if(scopeIds.length > 0) {
          showSynching();
          const addQuantities = await API.graphql({
              query: addDocOrders,
              variables: {
                  doc_id: doc_id,
                  doc_type_id : doc_type, 
                  scope_ids: scopeIds.join(','),
                  data: JSON.stringify(allData),
                  token: secureLocalStorage.getItem("user_token"),
                  action: 'template'
              }
          });
          
          const response = JSON.parse(addQuantities.data.addDocOrders);
          if(response.statusCode === 200) {
              allData.quantities = response.quantities; 
              allData.structures = response.structures;
          }
      }else {
        updateDocOnElasticSearch();
      }
      setStData(allData);
      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }
    props.setLoading(false);
  }

  const [modalShow6, setModalShow6] = React.useState(false);
  const [modalShow6x, setModalShow6x] = React.useState(false);

  const [sTempName, setsTempName] = React.useState('');
  const [sTempDesc, setsTempDesc] = React.useState('');

  function hadleSTempName(e){
    consoleLog('hadleSTempName>',e.target.value);
    setsTempName(e.target.value);
  }

  function hadleSTempDesc(e){
    consoleLog('hadleSTempDesc>',e.target.value);
    setsTempDesc(e.target.value);
  }

  function saveTemplateAsModal(){
    setsTempName(templateInfo.name);
    setModalShow6(true);
  }

  async function saveTemplateAss(){
    if(!sTempName){
      setSatErrorx(true);
      consoleLog('saveTemplateAss if');
      return false;
    }else{
      setSatErrorx(false);
      consoleLog('saveTemplateAss else');
    }
    setModalShow6(false);
    setLoading(true);

    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    consoleLog('saveTemplateAss client_id>',client_id);
    let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
    consoleLog('saveTemplateAss team_id>',team_id);
    let client_email = JSON.parse(secureLocalStorage.getItem('client_data')).email;
    consoleLog('saveTemplateAss client_email>',client_email);
    let doc_type = props.url_params.doc_type_id;
    consoleLog('saveTemplateAss sTempName>',sTempName);
    consoleLog('saveTemplateAss sTempDesc>',sTempDesc);
    consoleLog('saveTemplateAss stData>', stData);
    consoleLog('saveTemplateAss doc_type_id>', stData.doc_type_id);

    let all_data = {...stData};
    all_data.user_email = client_email;
    all_data.description = sTempDesc;
    all_data.name = sTempName;
    all_data.user_id = client_id;
    all_data.shared = 0;

    consoleLog('saveTemplateAss all_data>', all_data);
    showSynching();
    const create_tempx = await API.graphql({
      query: saveTemplateAs,
      variables: {
        doc_type_id: stData.doc_type_id,
        user_id: client_id,
        team_id: team_id,
        data: JSON.stringify(all_data),
        name: sTempName,
        update_user_id:client_id,
        description:sTempDesc
      }
    });
    let temp_arr = JSON.parse(create_tempx.data.saveTemplateAs);
    consoleLog('saveTemplateAss result>>>',temp_arr);
    setLoading(false);
    setModalShow3(true); //success message is not showing up
  }

  async function updateStrCount(add = true){

    let structure_count = stData.structures.length;
    structure_count = add ? ++structure_count : --structure_count;
    showSynching();
    const response = await API.graphql({
      query: updateStructureCount,
      variables: {
        doc_id: props.url_params.doc_id,
        structure_count: structure_count
      },
    });

    consoleLog("updateStrCount response", response);
    consoleLog("updateStrCount structure_count", structure_count);

    return structure_count;
    
  }

  consoleLog("stData REDUX.........",useSelector((state) => state.templateStructure), ".........", totalQuantity);
  consoleLog("Return loading", loading)
  return (
    <>
      <TemplateBody>
      {reportLoading && <div className="amp_fm_loading">
                  <div className="align_middle"></div>
                  <img className="loader" src={Loader} />			
              </div> }
        {modalShow6 == true &&
          <div className="modal-class">
              <Modal
                show={modalShow6}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{opacity:'1'}}
              >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <p>Save this document as a template to generate documents faster in the future.</p>
                    <input type="text" value={sTempName} className="tempModalInput" onChange={hadleSTempName} placeholder="Enter Template Name"/>
                    <input type="text" value={sTempDesc} className="tempModalInput" onChange={hadleSTempDesc} placeholder="Describe the template..."/>
                    { satErrorx &&
                      <p style={{color:"#dc3545"}}>Please enter Template Name</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="modal-btn-x" onClick={saveTemplateAss}>SAVE TEMPLATE </Button>
                    <Button className="modal-btn-x" onClick={()=>setModalShow6(false)}>CANCEL</Button>
                </Modal.Footer>
              </Modal>
          </div>
        }
        {typeof props.url_params.doc_type_id != 'undefined' ? (
          <DocNavbar
            showSettingOption={showSettingOptions}
            showDocDetail={showDocDetails}
            showLineItem={showLineItems}
            template_id={template_id}
            doc_id={props.url_params.doc_id}
            doc_type_id={props.url_params.doc_type_id}
            // url_params={props.url_params}
            applyDocumentTemplate={ApplyDocumentTemplate}
            saveDocEstimate={saveDocEstimate}
            saveDocWorkOrder={saveDocWorkOrder}
            loading={loading}
            setLoading={setLoading}
            modalShow5={modalShow5}
            setModalShow5={setModalShow5}
            set_show_taxes_and_markup={set_show_taxes_and_markup}
            saveAsTemplate={saveAsTemplate}
            tempId={tempId}
            setTempId={setTempId}
            modalShow3={modalShow3}
            setModalShow3={setModalShow3}
            updateDocOnElasticSearch={updateDocOnElasticSearch}
            satName={satName}
            setSatName={setSatName}
            satDesc={satDesc}
            setSatDesc={setSatDesc}
            satError={satError}
            setSatError={setSatError}
            modalShow6x={modalShow6x}
            setModalShow6x={setModalShow6x}
            setExitingTemplateID={setExitingTemplateID}
            exitingTemplateID={exitingTemplateID}
          />
        ) : (
          <TemplateNavbar
            showSettingOption={showSettingOptions}
            template_id={template_id}
            showDocDetail={showDocDetails}
            showLineItem={showLineItems}
            doc_id={props.url_params.doc_id}
            doc_type_id={props.url_params.doc_type_id}
            set_show_taxes_and_markup={set_show_taxes_and_markup}
            // url_params={props.url_params}
            modalShow3={modalShow3}
            setModalShow3={setModalShow3}
            loading={loading}
            setLoading={setLoading}
            saveTemplate={saveTemplate}
            save={save}
            setSave={setSave}
            modalShow2={modalShow2}
            setModalShow2={setModalShow2}
            saveTemplateAsModal={saveTemplateAsModal}
            updateDocOnElasticSearch={updateDocOnElasticSearch}
            setsTempName={setsTempName}
            setTemplateName={setTemplateName}
            templateName={templateName}
          />
        )}
        { loading ? (
            <LoadingContainer>
              <img className="loader" src={Loader}/>
            </LoadingContainer>
          ) : (
            <div className="doc-page content row doc-editor doc-template-editor is-estimate section-style-b estimate activated">
              <div className="wrap pl-0 pr-0">
                {(errorMsg || props.prevState) && <div class="alert alert-danger" role="alert">
                  <strong>Warning!</strong> There must be at least 1 line item that has a non-zero quantity to generate this document.
                </div>}
                {reportErrorMsg && <div class="alert alert-danger" role="alert">
                  {reportErrorMsg}
                </div>}
                {showLineItem && (
                  <>
                  { template_structures.length > 0 &&
                    <DragDropContext onDragEnd={handleOnDragEndStructure}>
                      <Droppable droppableId="droppable-1" type="droppable-1">
                        {(provided, _) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {Array.isArray(template_structures) &&
                              template_structures.length &&
                              template_structures.map((structure, i) => (
                                <Draggable
                                  key={i}
                                  draggableId={"draggable-" + i}
                                  type={"draggable-" + i}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                        boxShadow: snapshot.isDragging
                                          ? "0 0 .7rem #000"
                                          : "none",
                                      }}
                                    >
                                      {templateInfo.doc_type_id == 1 && (
                                        <Handle {...provided.dragHandleProps}>
                                          <img
                                            src={Move}
                                            style={{ paddingLeft: "5px" }}
                                            alt=""
                                          />
                                        </Handle>
                                      )}
                                      {templateInfo.doc_type_id == 1 && (
                                        <Structure
                                          template_structures={template_structures}
                                          changedCategoryPosition={onChangeCategoryPosition}
                                          draggable="true"
                                          allData={stData}
                                          categoryData={structure.categories}
                                          structures_data={structure}
                                          onChange={handleStructures}
                                          structureIndex={i}
                                          key={i}
                                          setSubTotal={setSubTotal}
                                          calculateSubTotal={calculateSubTotal}
                                          url_params={props.url_params}
                                          updateStrCount={updateStrCount}
                                          show={props.show}
                                          showMessage={props.showMessage}
                                          showMessageType={props.showMessageType}
                                          setShow={props.setShow}
                                          setShowMessage={props.setShowMessage}
                                          setShowMessageType={props.setShowMessageType}
                                          updateDocOnElasticSearch={updateDocOnElasticSearch}
                                          setLoading={setLoading}
                                          displayStructurePrice={displayStructurePrice}
                                          setDisplayStructurePrice={setDisplayStructurePrice}
                                          displayCategories={displayCategories}
                                          setDisplayCategories={setDisplayCategories}
                                          displayCategoryPrice={displayCategoryPrice}
                                          setDisplayCategoryPrice={setDisplayCategoryPrice}
                                        />
                                      )}

                                      {templateInfo.doc_type_id == 2 && (
                                        <WOStructure
                                          changedCategoryPosition={onChangeCategoryPosition}
                                          defaultManufacturers={props.defaultManufacturers}
                                          userManufacturers={props.userManufacturers}
                                          setUserManufacturers={props.setUserManufacturers}
                                          draggable="true"
                                          allData={stData}
                                          categoryData={structure.categories}
                                          structures_data={structure}
                                          onChange={handleStructures}
                                          structureIndex={i}
                                          key={i}
                                          url_params={props.url_params}
                                          show={props.show}
                                          showMessage={props.showMessage}
                                          showMessageType={props.showMessageType}
                                          setShow={props.setShow}
                                          setShowMessage={props.setShowMessage}
                                          setShowMessageType={props.setShowMessageType}
                                          updateDocOnElasticSearch={updateDocOnElasticSearch}
                                          calculateSubTotal={calculateSubTotal}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  }

                    {templateInfo.doc_type_id == 1 && (
                      <AddStructure
                        onChange={addNewStruct}
                        structData={stData}
                        url_params={props.url_params}
                        updateStrCount={updateStrCount}
                        show={props.show}
                        showMessage={props.showMessage}
                        showMessageType={props.showMessageType}
                        setShow={props.setShow}
                        setShowMessage={props.setShowMessage}
                        setShowMessageType={props.setShowMessageType}
                        updateDocOnElasticSearch={updateDocOnElasticSearch}
                      />
                    )}

                    <Items>
                      <ProjectNotes>
                        <p className="footer-note title m-0 mb-2">Project Notes</p>
                        <p className="desc m-0 mb-3">
                          Project notes apply to your entire estimate and will
                          appear at the bottom of your generated document.
                        </p>
                        <textarea
                          type="text"
                          className="pn"
                          value={project_notes}
                          onChange={handleProjectNotes}
                          placeholder="Enter Project Notes"
                          onBlur={updateProjectNotes}
                        />
                      </ProjectNotes>
                    </Items>

                    <Items>
                      <ToastBox
                        setShow={setToastShow}
                        show={toastShow}
                        Text={"Maximum 110 characters are allowed."}
                        Type={'Error'}
                      />
                      <ProjectNotes>
                        <p className="title footer-note m-0 mb-2">Footer Note</p>
                        <input
                          type="text"
                          className="pn-input"
                          value={footer_notes}
                          onChange={handleFooterNotes}
                          onBlur={updateFooterNotes}
                          placeholder="Enter Footer Notes (110 characters max)"
                        />
                      </ProjectNotes>
                    </Items>

                    {templateInfo.doc_type_id == 1 && (
                      <TaxesMarkup id="taxes">
                        <>
                          <Row className="w-100 markup-editor-box left is-reading m-0">
                            <div className="w-100 m-0 mb-2 tax-markup">
                              {!show_taxes_and_markup && (
                                <a
                                  className="on-reading"
                                  style={{
                                    height: "1.17%",
                                    width: "30.97%",
                                    color: "#212E33",
                                    fontFamily: "Roboto",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                    letterSpacing: "-1.07px",
                                    lineHeight: "28px",
                                  }}
                                  onClick={() =>{
                                    set_show_taxes_and_markup(true);
                                    setShowtax(true);
                                    setShowmarkup(true);
                                    setShowFinancing(true);
                                  }}
                                >
                                  Taxes, Markups, & Financing
                                  <span className="ml-2 tax-icon">
                                    {" "}
                                    <img src={TaxIcon} alt="" />{" "}
                                  </span>
                                </a>
                              )}
                              {show_taxes_and_markup && (
                                <a
                                  className="cancel-markup on-editing"
                                  style={{
                                    height: "1.17%",
                                    width: "30.97%",
                                    color: "#212E33",
                                    fontFamily: "Roboto",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                    letterSpacing: "-1.07px",
                                    lineHeight: "28px",
                                  }}
                                  onClick={() => set_show_taxes_and_markup(false)}
                                >
                                  Taxes, Markups, & Financing
                                  <span className="ml-2 tax-icon" >
                                    <img
                                      src={Check}
                                      alt=""
                                      style={{ cursor: "pointer",width:"26px" }}
                                    />
                                  </span>
                                </a>
                              )}
                            </div>

                            <div className="w-100 m-0 markup-totals on-reading">
                              <div
                                className="markup-list"
                                data-list-rel="markup-total"
                                data-count="1"
                              >
                                <Row className="subtotal m-0">
                                  <div className="col xs-10 subtotal-text">
                                    SUBTOTAL
                                  </div>
                                  <div
                                    className="col xs-2 text-right subtotal-price"
                                    style={{
                                      fontSize: "18px",
                                      fontFamily: "Roboto",
                                      fontWeight: "500",
                                    }}
                                  >
                                    ${subTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </div>
                                </Row>
                              </div>
                              {(!show_taxes_and_markup || (!showtax && !showmarkup)) && ( 
                                taxes.concat(markups).length ? (
                                <div className="markups-and-taxes">{
                                  taxes.concat(markups).map(markup=>(
                                    <Row className="markups">
                                      <Handle>
                                        <img
                                          src={Move2}
                                          class="move-markups"
                                        />
                                      </Handle>
                                      <div className="col xs-10 markups-text">
                                        {
                                          markup.type == "tax" ? (
                                            markup.for == "all" ? (
                                              `${markup.name} (${markup.value}% applied to ${markup.percent}% of Total Cost)`
                                            ) : (
                                              `${markup.name} (${markup.value}% applied to Material Costs only)`
                                            )
                                          ) : markup.type == "markup" ? (
                                            `${markup.name} (${markup.value}%)`
                                          ) : (
                                            `${markup.name} (${markup.value}% of items markups for O&P)`
                                          ) 
                                        }
                                      </div>
                                      <div className="col xs-2 text-right">
                                        {"$" + markup.total}
                                      </div>
                                    </Row>
                                  ))
                                }</div>
                                ) : (
                                  <Row className="markup-total-add m-0 ml-3 my-4  pt-3">
                                    <button
                                      className="add_taxes_markup bg-trans outline-none border-0 px-0"
                                      onClick={() => {set_show_taxes_and_markup(true);setShowtax(true);setShowmarkup(true)}}
                                    >
                                      <span className=" mr-2">
                                        <img style={{width:"26px" }} src={TaxPIcon} alt="" />
                                      </span>
                                      <span className="text-decor-underline pt-1">
                                        {" "}
                                        Add Tax or Markup{" "}
                                      </span>
                                    </button>
                                  </Row>
                                )
                              )}
                              {(show_taxes_and_markup && (showtax || showmarkup || showFinancing)) && (
                                <Row className="w-100 my-4 mx-auto">
                                  {showtax &&
                                  <AddTaxes
                                    stData={stData}
                                    subTotal={subTotal}
                                    matCostTotal={matCostTotal}
                                    calculateSubTotal={calculateSubTotal}
                                    setShowtax={setShowtax}
                                    default_tNm={default_tNm}
                                    set_default_tNm={set_default_tNm}
                                    url_params={props.url_params}
                                    show={props.show}
                                    showMessage={props.showMessage}
                                    showMessageType={props.showMessageType}
                                    setShow={props.setShow}
                                    setShowMessage={props.setShowMessage}
                                    setShowMessageType={props.setShowMessageType}
                                    updateDocOnElasticSearch={updateDocOnElasticSearch}
                                    hideMarkupOrTaxes={hideMarkupOrTaxes}
                                  />
                                  }
                                  {showmarkup && 
                                  <AddMarkups
                                    stData={stData}
                                    subTotal={subTotal}
                                    oNpCostTotal={oNpCostTotal}
                                    calculateSubTotal={calculateSubTotal}
                                    setShowmarkup={setShowmarkup}
                                    default_tNm={default_tNm}
                                    set_default_tNm={set_default_tNm}
                                    url_params={props.url_params}
                                    show={props.show}
                                    showMessage={props.showMessage}
                                    showMessageType={props.showMessageType}
                                    setShow={props.setShow}
                                    setShowMessage={props.setShowMessage}
                                    setShowMessageType={props.setShowMessageType}
                                    updateDocOnElasticSearch={updateDocOnElasticSearch}
                                    hideMarkupOrTaxes={hideMarkupOrTaxes}
                                  />
                                }
                                {showFinancing &&
                                  <AddFinancingOptions
                                    hideMarkupOrTaxes={hideMarkupOrTaxes}
                                    financingOptions={financingOptions}
                                    setFinancingOptions={setFinancingOptions}
                                    doc_id={props.url_params.doc_id}
                                    updateFinancingOptionsFunc={updateFinancingOptionsFunc}
                                  />
                                }
                                </Row>
                              )}
                              <Row className="total text-left m-0">
                                <div className="col xs-10 text-left total-text">
                                  TOTAL
                                </div>
                                <div
                                  className="col xs-2 text-right"
                                  style={{
                                    fontSize: "18px",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    color: "#fff",
                                  }}
                                >
                                  ${grandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                              </Row>
                            </div>
                          </Row>
                        </>
                      </TaxesMarkup>
                    )}
                  </>
                )}

                {showDocDetail && (
                  <DocDetails
                    is_doc={props.url_params.doc_type_id}
                    url_params={props.url_params}
                    updateDocOnElasticSearch={updateDocOnElasticSearch}
                    setReportLoading={setReportLoading}
                  />
                )}

                {showSettingOption && (
                  <DocSettings
                    is_doc={props.url_params.doc_type_id}
                    url_params={props.url_params}
                    updateDocOnElasticSearch={updateDocOnElasticSearch}
                    displayStructurePrice={displayStructurePrice}
                    setDisplayStructurePrice={setDisplayStructurePrice}
                    displayCategories={displayCategories}
                    setDisplayCategories={setDisplayCategories}
                    displayCategoryPrice={displayCategoryPrice}
                    setDisplayCategoryPrice={setDisplayCategoryPrice}
                  />
                )}

                {typeof props.url_params.doc_type_id != 'undefined' ? ( //Documents
                  <ItemsBottom>
                    {props.url_params.doc_type_id == 2 &&
                      <>
                        <button className="save_temp" onClick={()=>setModalShow6x(true)}>Save As Template</button>
                        <button className="generate_invoice2" onClick={materialCost}>
                          { !synching?'Material Cost':'Saving..' }
                        </button>
                        <button className="generate_estimates2" onClick={saveDocWorkOrder}>
                          { !synching?'Generate Work Order':'Saving..' }
                        </button>
                      </>
                    }
                    {props.url_params.doc_type_id == 1 &&
                      <>
                        <button className="save_temp" onClick={()=>setModalShow6x(true)}>Save As Template</button>
                        <button className="generate_estimates" onClick={saveDocEstimate}>
                          { !synching?'Generate Estimate':'Saving..' }
                        </button>
                        <button className="generate_invoice2" onClick={invoice}>
                          { !synching?'Generate Invoice':'Saving..' }
                        </button> 
                      </>
                    }
                  </ItemsBottom>
                ) : ( //Templates
                  <ItemsBottom>
                    {templateInfo.doc_type_id == 1 &&
                      <>
                        <button className="save_doc" onClick={saveTemplateAsModal}>Save As...</button>{" "}
                        <button className="generate_estimates" onClick={saveTemplate}>Save Template</button>
                      </>
                    }
                    {templateInfo.doc_type_id == 2 &&
                      <>
                        <button className="save_doc" onClick={saveTemplateAsModal}>Save As...</button>{" "}
                        <button className="generate_estimatex" onClick={saveTemplate}>Save Template</button>
                      </>
                    }
                  </ItemsBottom>
                )}
              </div>
            </div>
          )
        }
      </TemplateBody>
      <Footer />
      {synching && <img className="sync" src={Sync} />}
    </>
  );
}

const textBlack = "text-black";
const textRed = "text-red";


const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
    }
`

const CheckboxDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  .text-black {
    color: black;
  }
  .text-red {
    color: red;
  }
`;

const TemplateBody = styled.div`
  display: block;
  scroll-behavior:smooth;

  .amp_fm_loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(255,255,255,0.8);
    text-align: center;
  }

  .align_middle {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    margin-right: -.3em !important;
  }

  .doc-template-editor {
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
 
  .tempInputx {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .srchBtn {
    margin-left: 20px;
    background-color: #d6e1e5;
    width: 40px;
    padding: 9px;
    border-radius: 4px;
  }
  .help {
    font-size: 1em;
    color: #6a6e6f;
    margin-top: 0.2em;
  }
  .ip {
    width: 100%;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 2px 8px;
  }
  .border-top {
    border-top: 1px solid #bdbdbd;
  }
  .section-style-b {
    background: #f7f9fa;
    color: #414141;
    padding: 0 100px;

    @media (max-width: 1024px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 30px;
    }
  }
  .search-title {
    font-size: 0.9em;
    font-weight: 600;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0.5em;
    border: 1px solid #414141;
    border-bottom: 0;
  }

  .wrap {
    position: relative;
    margin: auto;
    width: 100%;
    min-width: 320px;
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
  }

  pl-0 {
    padding-left: 0px;
  }
  pr-0 {
    padding-right: 0px;
  }
  .doc-page .wrap {
    padding-top: 0;
  }

  @media (min-width: 480px) {
    .wrap {
      max-width: 768px;
      /* padding: 2em 1em; */
    }
  }
  @media (min-width: 768px) {
    .wrap {
      max-width: 1024px;
      /* padding: 2em 1em; */
    }
  }
  @media (min-width: 1024px) {
    .wrap {
      max-width: 1280px;
      /* padding: 2em 1em; */
    }
  }
  @media (min-width: 1280px) {
    .xs-hidden,
    .sm-hidden,
    .md-hidden,
    .lg-hidden,
    .xl-hidden,
    .xl-hide {
      display: none !important;
    }
    .wrap {
      max-width: 1280px;
      /* padding: 2em 0px; */
    }
  }
`;

const Btn = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: red;
  padding: 20px;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
`;

const ContentContiner = styled.div`
  min-height: 90vh;
  width: 100%;
`;

const Items = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin: 10px 0px;
  padding-bottom: 3rem;

  @media(max-width: 767px){
    padding-bottom: 0.5rem;
  }

  .hover-custom {
    text &:hover {
      color: red !important;
    }
  }
  .text-left {
    text-align: left;
  }
  .custom-btn {
    background: red;
    border: none;
    outline: none;
    padding: 4px;
    border-radius: 3px;
  }
  .addItems {
    min-height: 40px;
    width: 100%;
    background: #e3e3e3;
    padding: 10px 0;
    button {
      text-decoration: underline;
      padding: 5px;
    }

    .lineItemBox {
      min-height: 60px;
      background: #f5f5f5;
      padding: 5px;
    }
  }

  .addcatagory {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: flex-start;
    padding: 5px;
    background: #6a6e6f;

    p {
      color: #ffffff;
    }
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mr10 {
    background: transparent;
    border: none;
    color: black;
    font-weight: 500;
    margin: 0 5px;
    outline: none;
  }
  .table {
    @media (max-width: 600px) {
      padding: 0.55rem !important;
    }
    @media (max-width: 320px) {
      padding: 0.1rem !important;
    }

    thead {
      border-bottom: 2px solid #c2c2c2;
      tr {
        background: transparent !important;
        th {
          font-weight: 500 !important;
          border: none !important;
          color: black;
          padding: 0 10px;
          font-size: 14px;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px !important;
          white-space: nowrap;
        }
      }
    }
  }

  .index {
    z-index: 99999 !important;
  }

  .ip {
    width: 100%;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #414141;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 2px 8px;
  }

  .custombtnn {
    background: transparent;
    border: none;
    outline: none;

    
  }
  .black {
    text-color: black;
  }

  .customBorder {
    border-right: 1px solid #c2c2c2;
  }
  .customBorderBottom {
    border-bottom: 1px solid #c2c2c2;
  }

  .col-btn {
    height: 35px;
    width: 100%;
    background-color: red;
    color: #ffffff;
    border-radius: 5px;
    outline: none;
    border: none;
    margin: 5px 0;
  }

  .ChooseReport {
    min-height: 300px;
    width: 100%;
    border: 1px solid black;

    .header_Report {
      background-color: gray;
      padding: 5px;
      .title {
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .body_Report {
      overflow-y: scroll;
      text-align: left;
      color: red;
      margin: 5px 0;
      padding: 10px;

      .title {
        border-bottom: 1px solid #c2c2c2;
      }
    }
  }

  .catBox_child {
    width: 98.5%;
    border: 1px solid #b4bebf;
    border-radius: 5px;
    padding: 5px 0px 0px;
    button {
      border-bottom: 1px solid #c2c2c2;
    }
  }
`;

const TaxesMarkup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin: 10px 0px;
  padding-bottom: 3rem;

  @media(max-width: 767px) {
  padding-bottom: 0; 
  }
  .markup-list {
    // height: 100%;
    width: 100%;
    border-radius: 6px;
    background-color: #d6e1e5;
  }

  .tax-markup {
    a {
      @media(max-width: 767px){
        font-size: 18px !important;
      }
    }
  }

  .tax-icon {
    img {
      @media(max-width: 767px){
        width: 18px !important;
      }
    }
  }

  .cancel-markup {
    @media(max-width: 767px){
      font-size: 18px !important;
    }
  }

  .total-text {
    width: 10.28%;
    color: #ffffff;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 0px;
  }
  .subtotal-text {
    width: 10.28%;
    font-weight: normal;
    color: #212e33;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 0;

    @media(max-width: 767px) {
      font-size: 14px !important;
    }
  }

  .subtotal-price {
    @media(max-width: 767px) {
      font-size: 14px !important; 
    }
  }
  .markup-editor-box {
    margin-top: 2em;
    padding-top: 1.5em;
  }
  .red-on-hover:hover {
    cursor: pointer !important;
    color: red !important;
  }
  .doc-page .doc-form-editor h2 {
    font-size: 0.9em;
    font-weight: bold;
    margin: 0 0 1em 0;
  }
  .add_taxes_markup {
    font-size: small;
  }
  .text-decor-underline {
    font-size: 16px;
    cursor: pointer;
    font-family: Rajdhani;
    font-weight: 600;
    text-transform: uppercase;
  }
  .subtotal {
    background: #d6e1e5;
    font-size: medium;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0 25px;
  }
  .color-red {
    color: red;
  }
  .total {
    // height: 2.56%;
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #212e33;
    padding: 0 25px;
  }

  .markup-totals {
    padding: 0.5em 0px;
    font-size: medium;
  }

  .hover-custom {
    text &:hover {
      color: red !important;
    }
  }
  .text-left {
    text-align: left;
  }
  .custom-btn {
    background: red;
    border: none;
    outline: none;
    padding: 4px;
    border-radius: 3px;
  }
  .addItems {
    min-height: 40px;
    width: 100%;
    background: #e3e3e3;
    padding: 10px 0;
    button {
      text-decoration: underline;
      padding: 5px;
    }

    .lineItemBox {
      min-height: 60px;
      background: #f5f5f5;
      padding: 5px;
    }
  }

  .addcatagory {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: flex-start;
    padding: 5px;
    background: #6a6e6f;

    p {
      color: #ffffff;
    }
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mr10 {
    background: transparent;
    border: none;
    color: black;
    font-weight: 500;
    margin: 0 5px;
    outline: none;
  }
  .table {
    @media (max-width: 600px) {
      padding: 0.55rem !important;
    }
    @media (max-width: 320px) {
      padding: 0.1rem !important;
    }

    thead {
      border-bottom: 2px solid #c2c2c2;
      tr {
        background: transparent !important;
        th {
          font-weight: 500 !important;
          border: none !important;
          color: black;
          padding: 0 10px;
          font-size: 14px;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px !important;
          white-space: nowrap;
        }
      }
    }
  }

  .index {
    z-index: 99999 !important;
  }

  .ip {
    width: 100%;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #414141;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 2px 8px;
  }

  .custombtnn {
    background: transparent;
    border: none;
    outline: none;
  }
  .black {
    text-color: black;
  }

  .customBorder {
    border-right: 1px solid #c2c2c2;
  }
  .customBorderBottom {
    border-bottom: 1px solid #c2c2c2;
  }

  .col-btn {
    height: 35px;
    width: 100%;
    background-color: red;
    color: #ffffff;
    border-radius: 5px;
    outline: none;
    border: none;
    margin: 5px 0;
  }

  .ChooseReport {
    min-height: 300px;
    width: 100%;
    border: 1px solid black;

    .header_Report {
      background-color: gray;
      padding: 5px;
      .title {
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .body_Report {
      overflow-y: scroll;
      text-align: left;
      color: red;
      margin: 5px 0;
      padding: 10px;

      .title {
        border-bottom: 1px solid #c2c2c2;
      }
    }
  }

  .catBox_child {
    width: 98.5%;
    border: 1px solid #b4bebf;
    border-radius: 5px;
    padding: 5px 0px 0px;
    button {
      border-bottom: 1px solid #c2c2c2;
    }
  }

  .taxes {
    padding: 10px 28px 10px 24px;
  }
  .taxes:nth-child(2n+1) {
    background: #ECECEC;
  }

  .markups {
    background: white;
    font-size: medium;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0 25px;
    color: #212e33;
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    margin: 5px 0;

    @media(max-width: 767px){
      margin: 0;
      font-size: 13px;
    }
  }

  .markups-text {
    @media(max-width: 767px){
      padding: 0;
    }
  }
  .markups .move-markups{
    padding-left: 5px;
    padding-bottom: 3px;
    cursor: default;
  }
`;

const ProjectNotes = styled.div`
  width: 100%;
  margin: 10px 0;

  .title {
    font-size: 24px;
    font-family: Roboto;
  }

  .desc {
    font-size: 12px;
    font-family: Roboto;
  }

  .pn {
    height: 100px;
    width: 100%;
    text-align: start;
    outline: none;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #b4bebf;
    font-style: normal;
    color: #212e33;
    font-size: 14px;
  }
  .pn-input {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    text-align: start;
    outline: none;
    padding: 5px;
    border: 1px solid #b4bebf;
    font-style: normal;
    color: #212e33;
    font-size: 14px;
  }

  .footer-note {
    @media(max-width: 767px) {
      font-size: 18px !important;
    }
  }

  .span {
    color: black;
    font-size: 12px !important;
  }
`;

const NestedAccordian = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const ItemsBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px 0px;
  margin: 20px 0 246px 0;

  @media (max-width: 1080px) {
    margin: 20px 0 30px 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  span {
    height: 34px;
    width: 3px;

    @media (max-width: 600px) {
      display: none;
    }
  }
  .generate_estimates {
    height: 44px;
    width: 180px;
    background: #06d6a0;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 4px;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: 600;
    &:hover {
      color: #212e33;
      background-color: #d6e1e5;
    }
  }
  .generate_estimatex {
    height: 44px;
    width: 180px;
    background: #4cadff;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 4px;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: 600;
    &:hover {
      color: #212e33;
      background-color: #d6e1e5;
    }
  }
  .generate_invoice {
    height: 44px;
    width: 180px;
    background: #f7941c;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 4px;
    margin: 0 10px;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 600px) {
      margin: 10px 0;
    }
  }
  .generate_estimates2 {
    height: 44px;
    width: 211px;
    background: #4cadff;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 4px;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      color: #212E33;
      background-color: #D6E1E5;
    }
  }
  .generate_invoice2 {
    height: 44px;
    width: 156px;
    background: #f7941c;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 4px;
    margin: 0 10px;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      color: #fff;
      background-color: #000 !important;
    }
    @media (max-width: 600px) {
      margin: 10px 0;
    }
  }

  .save_temp {
    height: 44px;
    width: 180px;
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: initial;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover  {
      color: #F7941C;
    }
  }
  .save_doc {
    height: 44px;
    width: 180px;
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    color: #212e33;
    font-family: Rajdhani;
    text-transform: initial;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover  {
      color: #F7941C;
    }
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

const Handle = styled.div`
  position: absolute;
  left: 15px;
  z-index: 9;
  margin: 18px 0px;
  cursor: grab;
`;
