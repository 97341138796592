import React,{useState} from 'react'
import Cancel from '../../assets/cross.svg';
import { Button,Modal, } from 'react-bootstrap';
import {navigate} from 'gatsby';
import Alert from '../../assets/alert.svg'
export default function ExitModal(props) {
    const redirectionHandel = () =>{
        navigate(props.redirect);
    }
    return (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{opacity:'1'}}
        >
          <Modal.Header>
            <img onClick={props.onHide} src={Cancel} style={{width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem' , backgroundColor: 'transparent' , cursor: 'pointer', border: 'none'}}/>
         
            <Modal.Title id="contained-modal-title-vcenter">
                <div className="text-center my-3">
                    <img src={Alert} style={{width:'75px', height:'auto'}} alt=""/>
                </div>
                <p className="modal-title-x mb-0">Are you sure you want to exit?</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0"></Modal.Body>
          <Modal.Footer style={{ paddingBottom:'35px'}}>
            <Button className="modal-btn-x" onClick={redirectionHandel}>Exit</Button>
            <Button className="modal-btn-x" onClick={props.onHide}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      );
  }
  
