import React, { useState } from 'react'

import { Button, Modal, } from 'react-bootstrap';
import Cancel from '../../assets/cross.svg';
export default function SaveLineAsDefault(props) {

  return (
    <Modal
      {...props}
      onHide={() => props.setShowModal(true)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      style={{ opacity: '1' }}
    >
      <Modal.Header>
        <img onClick={() => props.setShowModal(false)} src={Cancel} style={{ width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem', backgroundColor: 'transparent', cursor: 'pointer', border: 'none' }} />
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="modal-title-x text-center mt-5 mb-0">Save Line as Default?</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center p-2">The line item already exists.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-btn-x" onClick={() => {
          props.setShowModal(false);
          props.overwrite();
        }}>Overwrite</Button>
        <Button className="modal-btn-x" onClick={() => {
          props.setShowModal(false);
          props.rename();
        }}>Rename</Button>
        <Button className="modal-btn-x" onClick={() => props.setShowModal(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

