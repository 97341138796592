import React,{useState} from 'react'

import { Button,Modal, } from 'react-bootstrap';
import {navigate} from 'gatsby';
import CheckBox from '../../assets/checkmark3.svg';
export default function SaveAsEstimateModal(props) {
    return (
        <Modal
          {...props}

          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{opacity:'1'}}
        >
          <Modal.Header>

            <Modal.Title id="contained-modal-title-vcenter">
                <div className="text-center my-3">
                    <img src={CheckBox} style={{width:'60px', height:'auto'}} alt=""/>
                </div>
                <p className="modal-title-x mb-0 mt-2">Your Estimate Has Been Saved</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button className="modal-btn-x" onClick={()=>props.setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
  }
