import React,{useState} from 'react'
import "../Modals/Modals.css";
import { Button,Modal, } from 'react-bootstrap';
import Cancel from '../../assets/cross.svg';
import {navigate} from 'gatsby';
import Alert from '../../assets/alert.svg'
export default function SaveAsTempModalx(props) {
    React.useEffect(()=>{console.log(props,"props save template");},[]);
    function handleSatName(e) {
      // console.log('handleSatName', e.target.value);
      props.setSatName(e.target.value);
    }
    function handleSatDesc(e) {
      // console.log('handleSatDesc', e.target.value);
      props.setSatDesc(e.target.value);
    }
    React.useEffect(()=>{
      console.log("from saveastemplate ",props);
      if(props.exitingTemplateID>0){
        props.setSatName(findRecordNameByTemplateId(props.exitingTemplateID));
      }
    },[props.exitingTemplateID]);
    const findRecordNameByTemplateId = (templateId) => {
      const record = props.myTemplates.find(item => item.template_id == templateId);
      return record ? record.name : 'Record not found';
    };
    const handleSelectChange = (e) => {
        const selectedTemplateID = e.target.value;
        if(e.target.value>0){
          props.setExitingTemplateID(selectedTemplateID);
          props.setSatName(e.target.options[e.target.selectedIndex].text);
        }else{
          props.setExitingTemplateID(0);
          props.setSatName('');
        }
    };
    return (
        <Modal
          {...props}
          onHide={() => props.setModalShow(true)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{opacity:'1'}}
        >
          <Modal.Header>
          <img onClick={()=>props.setModalShow(false)} src={Cancel} style={{width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem' , backgroundColor: 'transparent' , cursor: 'pointer', border: 'none'}}/>
            <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mt-5">
            <p>Save this document as a template to generate documents faster in the future.</p>
            <select 
              className='tempModalInput' 
              onChange={handleSelectChange} 
              value={props.exitingTemplateID || 0}
            >
              <option value="0">Select Template</option>
              {props.myTemplates.map((item, index) => (
                <option key={index} value={item.template_id}>
                  {item.name}
                </option>
              ))}
            </select>
            <input type="text" value={props.satName} onChange={handleSatName} placeholder='Enter Template Name' className='tempModalInput'/>
            <input type="text" value={props.satDesc} onChange={handleSatDesc} placeholder='Describe the template...' className='tempModalInput'/>
            { props.satError &&
              <p style={{color:"#dc3545"}}>Please enter Template Name</p>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button className="modal-btn-x" onClick={props.saveAsTemplate}>Save Template</Button>
            <Button className="modal-btn-x" onClick={()=>props.setModalShow(false)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      );
  }
  
